<script>
import useHttp from '@/comp-functions/useHttp'
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { ref } from '@vue/composition-api'
import router from '@/router'

// import Stat from "./widget";
// import SalesAnalytics from "./sales-analytics";
// import Transaction from './transactionadmin';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Stat,
    // SalesAnalytics,
    // Transaction
  },
  setup() {
    const { $put } = useHttp()

    const form = ref({
      oldPassword: '',
      newPassword: '',
    })

    const confirmPassword = ref('')

    const validationForm = async () => {
      // const validated = await formRef.value.validate()
      
      // isLoading.value = true
      $put({
        url: 'master/users/change-password',
        data: form.value
      })
        .then(() => {
          // isLoading.value = false
          router.push({ name: 'apps-login' })
        })
        .catch(() => {
          // isLoading.value = false
        })
    }

    return {
      validationForm,
      form,
      confirmPassword
    }
  },
  data() {
    return {
      title: "Ubah Password",
      items: [
        {
          text: "Antar Port"
        },
        {
          text: "Home",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <!-- <h4 class="mb-0">HLP - Halim Perdanakusuma</h4> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8">
        <b-form>
          <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-lock-2-line auti-custom-input-icon"></i>
            <label for="userpassword">Password Lama</label>
            <input
              v-model="form.oldPassword"
              type="password"
              class="form-control"
              id="userpassword"
              placeholder="Enter password"
              :class="{ 'is-invalid': submitted && $v.password.$error }"
            />
            <div
              v-if="submitted && !$v.password.required"
              class="invalid-feedback"
            >Password is required.</div>
          </div>

            <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-lock-2-line auti-custom-input-icon"></i>
            <label for="userpassword">Password Baru</label>
            <input
              v-model="form.newPassword"
              type="password"
              class="form-control"
              id="userpassword"
              placeholder="Enter password"
              :class="{ 'is-invalid': submitted && $v.password.$error }"
            />
            <div
              v-if="submitted && !$v.password.required"
              class="invalid-feedback"
            >Password is required.</div>
          </div>

            <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-lock-2-line auti-custom-input-icon"></i>
            <label for="userpassword">Ulangi Password</label>
            <input
              v-model="confirmPassword"
              type="password"
              class="form-control"
              id="userpassword"
              placeholder="Enter password"
              :class="{ 'is-invalid': submitted && $v.password.$error }"
            />
            <div
              v-if="submitted && !$v.password.required"
              class="invalid-feedback"
            >Password is required.</div>
          </div>
          <div class="input-group">
            <b-button variant="primary" @click.prevent="validationForm">
              <i class="ri-save-line align-middle ml-2"></i>
              Submit
            </b-button>
          </div>

        </b-form>

      </div>
    </div>
  </Layout>
</template>